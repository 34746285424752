import type { DialogStylesType } from '@material-tailwind/react'

const dialog: DialogStylesType = {
  styles: {
    base: {
      container: {
        maxHeight: '100vh',
        overflowY: 'auto',
      },
    },
  },
}

export default dialog
